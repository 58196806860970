import { render, staticRenderFns } from "./zx-tag.vue?vue&type=template&id=4ed4d5ae&scoped=true&"
import script from "./zx-tag.vue?vue&type=script&lang=js&"
export * from "./zx-tag.vue?vue&type=script&lang=js&"
import style0 from "./zx-tag.vue?vue&type=style&index=0&id=4ed4d5ae&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ed4d5ae",
  null
  
)

export default component.exports